/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import uiBootstrap from 'angular-ui-bootstrap';
import translate from 'angular-translate';
import checklistModel from 'checklist-model';

import template from './targeting-template.html';

import resources from '../../../components/api/resources/resources';
import promiseSpinner from '../../common/directives/html/promise-spinner-component';
import { Uuid } from "../../../components/util/uuid";
import { TargetingHelper } from "../targeting-helper";
import tt from './targeting-template-form.html';

const MODULE_NAME = 'targeting-template-directive';

angular.module(MODULE_NAME, [translate, uiBootstrap, checklistModel, resources, promiseSpinner])

  .directive('adnTargetingTemplate', function($timeout, $q, $templateCache, adnListHelper, Team, LocalUserPermissions, TargetingTemplate, $translate) {
    return {
      restrict: 'A',
      template: template,
      scope: {
        hook: '=adnTargetingTemplate',
        mode: '@',
        isLockedDown: '<',
        targetType: '<',
        overridableTargeting: '<',
        mandatory: '<',
        underModel: '='
      },
      link: function(scope) {

        $templateCache.put("targetingTemplateForm.html", tt);

        scope.toggleCopy = function() {
          scope.enabled = scope.view === 'SAVE' ? true : !scope.enabled;
          scope.saved = false;

          if (scope.enabled) {
            scope.view = 'COPY';

            const tplData = scope.hook.getTemplateData();

            if (!scope.copyTemplateList) {
              scope.copyTemplateList = scope.copyTemplateList || {};
              adnListHelper.setUpBasicList(scope.copyTemplateList, TargetingTemplate, 'targetingTargetingTemplates', {filterBy: 'type', filterByLike: tplData.constant.templateType});
            } else if (scope.refreshCopy) {
              scope.copyTemplateList.makeQuery();
              scope.refreshCopy = false;
            }
          }
        };
        scope.selectTeams = function(template) {
          template.teams = _.map(scope.allTeams, 'id');
        };

        scope.deselectTeams = function(template) {
          template.teams = [];
        };

        function teamQuery(template) {
          function doType() {
            template.teamType = _.get(scope, ['yourTeams', 'length']) === _.get(template, ['teams', 'length']) ? 'YOUR' : 'CUSTOM';
          }

          if (scope.allTeams) {
            doType();
            return;
          }
          return Team.query({minimal: true}).$promise.then(function(page) {
            scope.allTeams = page.results;
            const isViewAllObjects = LocalUserPermissions.hasNetwork('VIEW_ALL_OBJECTS');
            scope.yourTeams = _.filter(scope.allTeams, function(g) {
              return LocalUserPermissions.isPartOf(g.network.tagId || g.network.id, g.id);
            });
            scope.teams = isViewAllObjects ? scope.allTeams : scope.yourTeams;
            doType();
          });
        }

        scope.editInvoked = function(template) {
          template.editable = true;
          template.teams = _.map(template.teams, function(team) {
            return team.id || team;
          });
          teamQuery(template);
        };

        scope.editCancelled = function(template) {
          template.editable = false;
        };

        scope.copyInvoked = function(template) {
          scope.hook.copyTemplate(template);
          _.forEach(scope.copyTemplateList.items, function(item) {
            item.copy = false;
          });
          template.copy = true;
        };

        scope.deleteInvoked = function(template) {
          template.$archive();
          $timeout(function() {
            scope.copyTemplateList.makeQuery();
          }, 150);
        };

        scope.toggleSaved = function() {
          scope.enabled = scope.view !== 'SAVE' ? true : !scope.enabled;
          scope.saved = false;

          if (scope.enabled) {
            scope.view = 'SAVE';

            const tplData = scope.hook.getTemplateData();
            tplData.count = TargetingHelper.getSizeOfTargetingTypes(tplData.model, tplData.constant.targets);

            scope.saveCount = tplData.count;

            scope.model = {data: {}};
            scope.model.name = tplData.count + " " + $translate.instant("targeting." + tplData.constant.id + (tplData.count > 1 ? ".plural" : ""));
            scope.model.description = tplData.summary || scope.model.name;
            scope.model.data.model = tplData.model;
            scope.model.data.vm = tplData.vm;
            scope.model.type = tplData.constant.templateType;
            const resp = teamQuery(scope.model);
            $q.when(resp).then(function() {
              scope.model.teamType = 'YOUR';
            });
          }
        };

        scope.editTemplate = function(template) {
          const templateForSave = angular.copy(template);
          const nt = new TargetingTemplate(templateForSave);

          if (!nt.teamType || nt.teamType === 'YOUR') {
            nt.teams = scope.yourTeams;
          }

          nt.savePartial();
          scope.enabled = false;
          scope.saved = true;
          scope.refreshCopy = true;
        };

        scope.saveTemplate = function() {
          const templateForSave = angular.copy(scope.model);
          templateForSave.id = Uuid.generate();
          const nt = new TargetingTemplate(templateForSave);

          if (scope.model.teamType === 'ALL') {
            nt.teams = [];
          } else if (scope.model.teamType === 'YOUR') {
            nt.teams = scope.yourTeams;
          }

          nt.savePartial();
          scope.enabled = false;
          scope.saved = true;
          scope.refreshCopy = true;
        };
      }
    };
  });

export default MODULE_NAME;